<template>
    <div class="docs-container">
        <h2>List of Guides</h2>
        <template v-if="details.product.category.category_details.guides.length">
            <div class="guides">
                <template v-for="(guide, i) in details.product.category.category_details.guides" :key="i">
                    <div class="guide" @click="selectedGuide = guide.id">
                        <figure>
                            <img src="/assets/images/doc-icon.svg" alt="file_icon" />
                        </figure>
                        <div class="guide-desc">
                            <div class="guide">{{ guide.title }}</div>
                        </div>
                    </div>
                    <preview
                        v-if="selectedGuide"
                        :type="'step-desktop'"
                        :info="{ id: selectedGuide }"
                        @close="selectedGuide = undefined"
                    />
                </template>
            </div>
        </template>
        <template v-else>
            <span>There are no guides for this product</span>
        </template>
        
    </div>
</template>

<script>
import Preview from "@/components/previews/Preview.vue";
export default {
  components: {
      Preview
  },
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
  data() {
      return {
        imgURL: process.env.VUE_APP_DO_SPACES,
        selectedGuide: undefined,
      }
  },

    methods: {
        async goToDocument(document) {
            if (document.files.length > 1) {
                this.folder = true;
            } else {
                if (document.file) window.open(this.imgURL + document.file);
                else window.open(this.imgURL + document.files[0].file);
            }
        },
    },

  created() {
    this.ticketId = this.$route.params.ticketid;
  },
};
</script>

<style lang="scss" scoped>
    .docs-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        h2 {
            font-weight: bold;
            font-size: 1.3rem;
            margin: 0;
        }

        .guides {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .guide {
                display: flex;
                align-items: center;
                cursor: pointer;

                figure {
                    width: 30px;
                    height: 30px;
                }

                .guide-desc {
                    color: var(--primary-color);
                    user-select: none;
                    font-weight: 500;
                }
            }
        }
    }
</style>