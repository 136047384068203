<template>
    <div class="details-container">
        <div class="image" v-if="details.product.product_images.length">
            <img :src="imgURL + details.product.product_images[0].image" alt="" />
        </div>
        <div class="product">
            <span class="product-name">{{ details.product.name }}</span>
            <div class="product-info">
                <div class="item">
                    <span class="title">Serial number</span>
                    <span class="desc">{{ details.product.serial_no }}</span>
                </div>
                <div class="item">
                    <span class="title">Product Number</span>
                    <span class="desc">{{ details.product.product_number }}</span>
                </div>
                <div class="item">
                    <span class="title">Client</span>
                    <div class="desc" v-if="details.site && details.site.client">
                        <span>{{ details.site.client.client_details?.name }}</span>
                    </div>
                    <div class="desc" v-else>undefined</div>
                </div>
                <div class="item">
                    <span class="title">Site</span>
                    <span class="desc">{{ details.site.name }}</span>
                </div>
                <div class="item">
                    <span class="title">Warranty</span>
                    <span class="desc" v-if="details.product.warranty_date">{{
                        details.product.warranty_end_date
                    }}</span>
                    <span class="desc" v-else>Not defined yet</span>
                </div>
                <div class="item">
                    <span class="title">Maintenance</span>
                    <span class="desc" v-if="details.product.maintenance">{{
                        details.product.maintenance
                    }}</span>
                    <span class="desc" v-else>Not defined yet</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getCurrentInstance } from "vue";
export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: "Remote Support History",
      ticketId: "",
      priorityStatus: "",
      priorityFlag: undefined,
      internalInstance: getCurrentInstance(),
      imgURL: process.env.VUE_APP_DO_SPACES,
    };
  },
  created() {
    this.ticketId = this.$route.params.ticketid;
  },
};
</script>

<style scoped>
.details-container {
    padding: 20px;
    display: flex;
    gap: 20px;
}

.details-container .image {
    width: 30%;
}

.details-container .image img {
    width: 100%;
}

.details-container .product {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.details-container .product .product-name {
    font-weight: bold;
    font-size: 1.3rem;
}

.details-container .product .product-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.details-container .product .product-info .item {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.details-container .product .product-info .item .title {
    font-weight: 500;
}

.details-container .product .product-info .item .desc {
    font-weight: 600;
}
</style>