<template>
    <div class="timer" :class="{counting: status}" :title="status ? 'Click to Pause' : 'Click to Start Timer'"  @click="handleTimer">
        <figure>
            <img src="/assets/images/timer.svg" alt="">
        </figure>
        <span class="icon" :class="{paused: status, counting: !status}" />
    </div>
</template>

<script>
import axiosInstance from "@/services/AxiosTokenInstance";
export default {
    props: {
        timer: {
            type: Object,
            default: () => {
                return {
                    time: 0,
                    status: 0
                }
            }
        },

        ticket: {
            type: Number,
            default: () => 0
        }
    },

    emits: ['timerStatus'],

    data() {
        return {
            status: undefined,
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
        }
    },

    computed: {
        time() {
            return new Date(this.timer.time * 1000).toLocaleTimeString("default", {timeZone: 'GMT'})
        }
    },

    watch: {
        status(val) {
            this.$emit('timerStatus', val)
        },

        timer: {
            deep: true,
            handler(val) {
                this.status = val.status
            }
        }
    },

    created() {
        this.status = this.timer.status
    },

    methods: {
        async handleTimer() {
            const data = {
                org_id: this.org.id
            }
            const response = await axiosInstance.post(`ticket-timer/${this.ticket}`, data)

            if (response.status !== 200) {
                this.emitter.emit('alert', response.data.message)
                return
            }

            this.status = this.status ? 0 : 1
        }
    }
}
</script>

<style lang="scss" scoped>
    .timer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border: solid 1px grey;
        color: grey;
        // padding: 0px 10px;
        border-radius: 30px;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;
        height: 24px;
        width: 48px;
        border: solid 1px grey;

        &:hover {
            background: rgba(grey, 0.4);
            color: white;
        }

        &.counting {
            border-color: $red;
        }

        figure {
            margin-left: 4px;
            width: 14px;
            height: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
                height: 100%;
            }
        }

        .icon {
            width: 24px;
            height: 24px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            // background: white;
            margin: 0;



            &.paused {
                &::after, &::before {
                    content: '';
                    height: 10px;
                    width: 2px;
                    background: grey;
                    position: absolute;
                    transition: 0.2s;
                }

                &::after {
                    right: 8px;
                }

                &::before {
                    left: 8px;
                }
            }

            &.counting {
                &::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    background: $red;
                    border-radius: 50%;
                }
            }

            
        }

        span {
            margin-left: 8px;
            font-weight: 500;
            font-size: 12px;
        }
    }
</style>