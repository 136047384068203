<template>
    <div class="docs-container">
        <h2>List of Documents</h2>
        <template v-if="details.product.documents.length">
            <div class="documents">
                <template v-for="(document, i) in details.product.documents" :key="i">
                    <div class="document" @click="goToDocument(document)">
                        <figure class="img">
                            <template v-if="document.files.length <= 1">
                                <img src="/assets/images/doc-icon.svg" alt="file_icon" />
                            </template>
                            <template v-else>
                                <img src="/assets/images/folder_icon.svg" alt="folder_icon" />
                                <span class="folder">folder</span>
                            </template>
                        </figure>
                        <div class="doc-desc">
                            <div class="doc">{{ document.name }}</div>
                            <div class="info">
                                <span v-if="document.files.length > 1">
                                    Files inside: {{ document.files.length }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <preview
                        v-if="folder"
                        :type="'folder'"
                        :info="document"
                        @close="folder = false"
                    />
                </template>
            </div>
        </template>
        <template v-else>
            <span>There are no documents for this product</span>
        </template>
        
    </div>
</template>

<script>
import Preview from "@/components/previews/Preview.vue";
export default {
  components: {
      Preview
  },
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
  },
  data() {
      return {
        imgURL: process.env.VUE_APP_DO_SPACES,
        folder: false,
      }
  },

    methods: {
        async goToDocument(document) {
            if (document.files.length > 1) {
                this.folder = true;
            } else {
                if (document.file) window.open(this.imgURL + document.file);
                else window.open(this.imgURL + document.files[0].file);
            }
        },
    },

  created() {
    this.ticketId = this.$route.params.ticketid;
  },
};
</script>

<style lang="scss" scoped>
    .docs-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        h2 {
            font-weight: bold;
            font-size: 1.3rem;
            margin: 0;
        }

        .documents {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .document {
                display: flex;
                align-items: center;
                cursor: pointer;

                figure {
                    width: 30px;
                    height: 30px;
                }

                .doc-desc {
                    color: var(--primary-color);
                    user-select: none;
                    font-weight: 500;
                }
            }
        }
    }
</style>