<template>
    <div class="wrapper">
        <template v-if="roomCallStatus && !getCallId">
            <div class="join" @click="startCall">
                <span>There is an ongoing call in this room, click here to join it!</span>
                <figure>
                    <img src="/assets/images/phone-icon.png" alt="" />
                </figure>
            </div>
        </template>
        <div class="video-wrapper" :class="{ 'w-video': session && !getDisabled }">
            <div class="innerVideo" id="videochat"></div>
        </div>
        <div class="whole" :class="{ 'w-video': session && !getDisabled }">
            <div class="chat">
                <div class="chat-wrapper" ref="wrapper" @scroll="handleScroll">
                    <div class="chats-tab" v-if="chats.length && canCreate.includes(getUserProfile.role.id)">
                        <template v-for="(chat, i) in chats" :key="i">
                            <div class="chat-bubble"
                                :class="{ main: chat.type === 'ticket', support: chat.type !== 'ticket', active: activeChat === chat._id }"
                                @click="activeChat = chat._id">
                                <div class="icon">
                                    <figure v-if="chat.type === 'support' && getSupportChatName(chat).avatar">
                                        <img :src="imgURL + getSupportChatName(chat).avatar" alt="user">
                                    </figure>
                                    <div v-else class="no-img">
                                        <span>{{ chat.type === 'ticket' ? 'M' : chat.type === 'internal' ? 'I' :
                                            getSupportChatName(chat).name.charAt(0) }}</span>
                                    </div>
                                    <span v-if="chat.unreads" class="unreads">{{ chat.unreads }}</span>
                                </div>
                                <span v-if="chat.type === 'ticket'">Main Chat</span>
                                <span v-else-if="chat.type === 'internal'">Internal</span>
                                <span v-else-if="chat.type === 'support'">{{ getSupportChatName(chat).name }}</span>
                            </div>
                            <hr v-if="chat.type === 'ticket'">
                        </template>
                    </div>
                    <details-info :ticket="productDetails" />
                    <template v-if="loadingMessages && chatLoaded">
                        <div class="loading messages">
                            <span class="loader" />
                        </div>
                    </template>
                    <template v-if="chatLoaded">
                        <template v-for="(chat, chat_index) in chatsList" :key="chat_index">
                            <Messages :chat="chat" :replying-to-me="replyingTo && replyingTo._id === chat._id"
                                @removeChat="handleRemoveChat" @replyToChat="handleReplyToChat" />
                        </template>
                        <template v-for="(chat, chat_index) in newMessages" :key="chat_index">
                            <Messages :chat="chat" :replying-to-me="replyingTo && replyingTo._id === chat._id"
                                @removeChat="handleRemoveNewChats" @replyToChat="handleReplyToNewChat" />
                        </template>
                    </template>
                </div>
                <template v-if="chatLoaded">
                    <Chat v-if="canChat" :timer-status="timerStatus"
                        :need-timer="canCreate.includes(getUserProfile.role.id)" :roomId="chatDetails.room"
                        :replying-to="replyingTo" @cancelReply="replyingTo = undefined" @sentMessage="handleFirstMessage" />
                </template>
                <template v-else>
                    <div class="loading">
                        <span class="loader"></span>
                    </div>
                </template>
            </div>
            <transition name="slide">
                <chat-extras v-if="edit" :info="details" :ticket="true" :room="chatDetails.room"
                    :canEdit="canCreate.includes(getUserProfile.role.id)" :type="type" @searchkey="handleSearch"
                    @supportChat="support = true" @add="addUsers = true" @editTicket="editTicketName = true" />
            </transition>
        </div>
        <edit-modal :title="`Edit ticket Name`" :items="toEditName" :response="response" v-if="editTicketName"
            @close="editTicketName = false" @submitData="editTicketData" />
        <users-to-chat v-if="support" @close="support = false" @data="handleSupportChat" />
        <users-to-chat v-if="addUsers" @close="addUsers = false" @data="handleAddUsers" />
    </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import axiosInstance from '@/services/AxiosTokenInstance'
import nodeInstance from "@/services/NodeServiceinstance";
import {
  GET_CHATS_LIST,
  UPLOAD_CHAT_ATTACHMENT,
  GET_USER_PROFILE_GETTER
} from "@/store/storeconstants";
import ChatExtras from "@/components/Ui/Chat/ChatExtras.vue";
import DetailsInfo from "@/components/Chat/Details.vue";
import UsersToChat from "@/components/Ui/Chat/UsersToChat.vue";
import Chat from "@/components/Chat/Chat.vue";
import Messages from "@/components/Chat/Messages.vue";
import EditModal from "@/components/Ui/CRUD/Edit.vue";
export default {
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
    edit: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    type: {
      required: false,
      type: Array,
      default: () => [],
    },
    initCall: {
        type: Boolean,
        default: () => false,
    },
    unreads: {
        type: Number,
        default: () => 0
    },
    callSpecificUsers: {
        type: Array,
        default: () => []
    },
    timerStatus: {
        type: Number,
        default: () => 0
    }
  },
  data() {
    return {
        chats: [],
        activeChat: undefined,
        chatPages: 1,
        internalInstance: getCurrentInstance(),
        currentChatPage: 1,
        chatLoaded: false,
        chatDetails: {},
        newMessages: [],
        currentScroll: 0,
        showVid: false,
        user: localStorage.getItem("U_P")
            ? JSON.parse(localStorage.getItem("U_P"))
            : {},
        session: false,
        imgURL: process.env.VUE_APP_DO_SPACES,
        canChat: false,
        editTicketName: false,
        support: false,
        addUsers: false,
        org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
        supportChats: [],
        canCreate: [4, 5, 6, 7, 8],
        activeSupport: undefined,
        internalChat: undefined,
        response: [],
        roomCallStatus: null,
        unreadsMsgs: 0,
        loadingMessages: false,
        chatsList: [],
        replyingTo: undefined,
        searchkey: undefined
    };
  },

  components: {
    DetailsInfo,
    Chat,
    Messages,
    ChatExtras,
    EditModal,
    UsersToChat
  },

  computed: {
    productDetails() {
      return {
        product: {
          name: this.details.product?.name,
          serial_no: this.details.product?.serial_no,
          warranty_end_date: this.details.product?.warranty_end_date,
          client: {
            name: this.details.client?.name,
          },
          ref: this.details.product?.model,
        },
        project: this.details?.site,
        description: this.details?.description,
        image: this.details.product
          ? this.details.product.product_images.length
            ? this.imgURL + this.details.product.product_images[0].image
            : this.details.client.logo
            ? this.imgURL + this.details.client.logo
            : null
          : null
      };
    },

    toEditName() {
        return [
            {
                type: "text",
                label: "Ticket name",
                value: this.details.title,
            }
        ];
    },

    ...mapGetters({
      getCallStatus: "call/getCallStatus",
      getCallId: "call/getCallId",
      getTicketId: "call/getTicketId",
      getDisabled: "call/getDisabled",
    }),
    
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
  },

  watch: {
    initCall(val) {
        if(val) this.startCall()
    },

    callSpecificUsers(val) {
        if (!val.length) {
            return
        }

        this.startCall()
    },

    unreads: {
        immediate: true,
        handler(val) {
            this.unreadsMsgs = val
        }
    },

    getCallId: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.session = false;
          setTimeout(() => {
            this.setDisabled(true);
          }, 50);
        }
        if (val && val === this.chatDetails.room) {
          this.session = true;
          setTimeout(() => {
            this.setDisabled(false);
          }, 50);
        } else {
          this.session = false;
          this.setDisabled(true);
        }
      },
    },

    details: {
      immediate: true,
      handler() {
        this.newMessages = []
        this.currentScroll = 0;
        this.chatLoaded = false;
        this.currentChatPage = 1;
        this.getChatData();
      },
    },

    currentChatPage() {
      this.getChatList();
    },

    chatDetails: {
      deep: true,
      handler(val) {
        this.$emit('closeEdit')
        this.newMessages = []
        
        if (!this.getCallId) {
          this.session = false;
          setTimeout(() => {
            this.setDisabled(true);
          }, 50);
        } else if (this.getCallId && val.room === this.getCallId) {
          this.session = true;
          setTimeout(() => {
            this.setDisabled(false);
          }, 50);
        } else {
          this.session = false;
          this.setDisabled(true);
        }
      },
    },

    chats(val, oldVal) {
        this.newMessages = []
        if (val.length && !oldVal.length) {
            this.activeChat = val[0]._id
        }
    },

    activeChat() {
        this.newMessages = []

        if (this.currentChatPage !== 1) {
            this.currentChatPage = 1
            return
        }
        this.getChatList()
    },
  },

  methods: {
    ...mapActions("chat", {
      fetchChatList: GET_CHATS_LIST,
      updateChatFile: UPLOAD_CHAT_ATTACHMENT,
    }),

    ...mapActions({
      getRoomCallStatus: "chat/getRoomCallStatus"
    }),

    ...mapMutations({
      setDisabled: "call/setDisabled",
      setCallStatus: "call/setCallStatus",
      setCallId: "call/setCallId",
      setTicketId: "call/setTicketId",
      setUsersInCall: "call/setUsersInCall",
      setCallInfo: "call/setCallInfo",
    }),

    async getChatData() {
        if (!this.details?.id) {
            return
        }

        const chats = await nodeInstance.get(`chats/ticket-rooms/${this.details.id}`)
        
        if (chats.status === 200 && !chats.data.length) {
            this.emitter.emit("create-room", {
                type: "ticket",
                ticketId: this.details.id,
                organizationId: this.org.id,
            });
            setTimeout(this.getChatData, 500);
            return
        }
        
        this.chats = chats.data

        this.$emit('setUnreads', this.getTotalUnreads())
    },

    async getChatList() {
        if (this.currentChatPage === 1) this.chatLoaded = false;
        this.loadingMessages = true

        let extra = ''

        if (this.searchkey && this.searchkey.length) {
            extra = `&searchData=${this.searchkey}`
        }

        const response = await nodeInstance.get(`chats/chat-list?room=${this.activeChat}&page=${this.currentChatPage}${extra}`)

        const chat = this.chats.find(el => el._id === this.activeChat)
        if (chat.users?.map(el => el.userId).includes(this.user?.id)) this.canChat = true
        
        this.loadingMessages = false
        this.chatLoaded = true
        
        this.$emit('activeRoom', response.data.room)
        this.emitter.emit('read-room-chats', {room: response.data.room})
        

        if (this.currentChatPage !== 1) {
            const currentScroll = this.$refs.wrapper.scrollHeight
            const newChats = response.data.chatDetails.reverse();
            this.chatsList.unshift(...newChats);
            setTimeout(() => {
                this.$refs.wrapper.scrollTop = this.$refs.wrapper.scrollHeight - currentScroll
            }, 1);
            return
        }

        this.totalChats = response.data.totalChatCount
        this.chatPages = response.data.pages
        this.chatDetails = response.data
        this.chatsList = response.data.chatDetails.reverse()

        setTimeout(() => {
            this.$refs.wrapper.scrollTop = this.$refs.wrapper.scrollHeight
        }, 1);
    },

    getSupportChatName(chat) {
        const user = chat.users.filter(el => el.userId !== this.user.id)
        return user.length ? user[0]?.userInfo : {avatar:null, name: 'Support'}
    },

    handleRemoveChat(data) {
        const index = this.chatsList.findIndex(el => el._id === data)
        this.chatsList.splice(index, 1)
    },

    handleRemoveNewChats(data) {
        const index = this.newMessages.findIndex(el => el._id === data)
        this.newMessages.splice(index, 1)
    },

    handleReplyToChat(data) {
        const item = this.chatsList.find(el => el._id === data)
        this.replyingTo = item
    },

    handleReplyToNewChat(data) {
        const item = this.newMessages.find(el => el._id === data)
        this.replyingTo = item
    },

    handleSupportChat(data) {
      const params = {
        type: "support",
        ticketId: this.details.id,
        organizationId: this.org.id,
        participants: data.map((el) => el.id),
      };

      this.emitter.emit("create-room", params);
      this.getChatData()
    },
    
    handleAddUsers(data) {
      const params = {
        roomId: this.chatDetails.room,
        participantIds: data.map((el) => el.id),
      };
      
      this.emitter.emit("add-participants-to-room", params);
    },

    handleSearch(data) {
        this.searchkey = data
        if (this.currentChatPage !== 1) {
            this.currentChatPage = 1;
            return
        }
      
        this.getChatList();
    },

    handleScroll(e) {
      if (e.target.scrollTop === 0 && this.currentChatPage < this.chatPages) {
        this.currentScroll = e.target.scrollHeight;
        this.currentChatPage += 1;
      }
    },

    getTotalUnreads() {
        return this.chats.map(el => el.unreads).reduce((acc, cur) => acc + cur, 0)
    },

    async editTicketData(data) {
		let toSend = {
			org_id: this.org.id,
			title: data["Ticket name"]
		};

		const response = await axiosInstance.post(
            `modify-ticket-name/${this.details.id}`,
			toSend
		);

        if (response.status !== 200) {
            this.response = [true, false]
            this.emitter.emit('alert', response.data.message)
            return
        }

        this.response = [true, true]
        this.emitter.emit("alert", response.data.message);
        this.$emit('reload')
	},

    async checkStatus() {
        this.roomCallStatus = await this.getRoomCallStatus({room: this.chatDetails.room,})
    },

    startCall() {
        let userToSend = this.user;
        userToSend.type = "single";
        const params = {
            roomId: this.chatDetails.room,
            with: userToSend,
        };

        if (this.callSpecificUsers.length) {
            params.specificUsers = this.callSpecificUsers
        }
        
        this.setCallStatus(true);
        this.setCallId(this.chatDetails.room);
        this.setTicketId(this.details.id)
        this.setCallInfo({
            type: 'ticket',
            id: this.details.id,
            name: this.details.title,
            avatar: this.details.product
            ? this.details.product.product_images.length
                ? this.imgURL + this.details.product.product_images[0].image
                : this.details.client.logo
                ? this.imgURL + this.details.client.logo
                : null
            : null
        })
        
        this.emitter.emit("video-section-prepared", params);
        if (!this.roomCallStatus) this.emitter.emit("request-to-join-call", params);

        this.$emit('emptyUsers')
    },

    joinRoom() {
      const params = {
        ticketId: this.details.id,
      };
      this.emitter.emit("join-room", params);
    },

    async handleFirstMessage() {
      const assignedUsers = this.details.assigned_users.map(el => el.id)
      const sentByAssigned = this.chatDetails.chatDetails.filter(el => assignedUsers.includes(el.sentBy.userId)).length + this.newMessages.filter(el => assignedUsers.includes(el.sentBy.userId)).length


      if (!sentByAssigned && assignedUsers.includes(this.user.id)) {
        const toSend = {
          ticket_id: this.details.id,
          status_id: 3
        }

        await axiosInstance.post('change-ticket-status', toSend)
      }
    }
  },

  created() {
    this.emitter.on("session-started", (data) => {
        const room = data.room
        if (room === this.chatDetails.room) this.checkStatus()
    })


    this.emitter.on("room-read", () => {
      const index = this.chats.findIndex(el => el._id === this.activeChat)
      if (index >= 0) {
        this.chats[index].unreads = 0
        this.$emit('setUnreads', this.getTotalUnreads())
      }
    });

    this.emitter.on("setConfigForCall", () => {
        if(this.getTicketId) this.emitter.emit('create-ticket-call', {ticketId: this.details.id})
    })

    this.emitter.on("new-message", (data) => {
        const rooms = this.chats.map(el => el._id)

        if (data.room._id === this.chatDetails.room) {
            if(data.type === 'endCall' || data.type === 'rejectCall') this.checkStatus()

            let msg = {
                _id: data._id,
                attachmentUrls: data.attachmentUrls,
                createdAt: data.createdAt,
                media: data.media,
                receivers: data.receivers,
                room: data.room,
                sentBy: data.sentBy,
                status: data.status,
                isEdited: false,
                text: data.text,
                type: data.type,
                mentionedUsers: data.mentionedUsers,
                replyTo: data.replyTo || null
            }

            this.newMessages.push(msg);

            setTimeout(() => {
                const wrapper = this.$refs.wrapper;
                if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;
            }, 200);
            return
        }

        if (rooms.includes(data.room._id)) {
            const index = this.chats.findIndex(el => el._id === data.room._id)
            this.chats[index].unreads += 1
            return
        }
    });

    this.emitter.on("chats-deleted", (data) => {
      if (data.room === this.chatDetails.room) {
        this.newMessages = [];
        this.chatDetails.chatDetails = [];
        this.chatDetails.totalChatCount = 0;
      }
    });

    this.emitter.on("room-chat-deleted", (data) => {
        if (data.room !== this.chatDetails.room) {
            return
        }

        const chat = this.chatsList.findIndex(el => el._id === data.chat)

        if (chat >= 0) {
            this.chatsList.splice(chat, 1);
            return
        }

        const newChat = this.newMessages.findIndex(el => el._id === data.chat)
        this.newMessages.splice(newChat, 1);
    })

    this.emitter.on("room-chat-edited", (data) => {
        if (data.roomId !== this.chatDetails.room) {
            return
        }

        const chat = this.chatsList.findIndex(el => el._id === data._id)

        if (chat >= 0) {
            this.chatsList[chat].text = data.text;
            this.chatsList[chat].isEdited = true;
            this.chatsList[chat].updatedAt = data.updatedAt;
            return
        }

        const newChat = this.newMessages.findIndex(el => el._id === data._id)
        if (newChat>= 0) {
            this.newMessages[newChat] = data.text;
            this.newMessages[newChat].isEdited = true;
            this.newMessages[newChat].updatedAt = data.updatedAt;
            return
        }
    })

    this.emitter.on("user-typing", (data) => {
      if (
        data.room === this.chatDetails.room &&
        data.userDetails.userId !== this.user.id
      ) {
        this.userIsTyping = true;
        const wrapper = this.$refs.wrapper;
        if (wrapper) wrapper.scrollTop = wrapper.scrollHeight;

        clearTimeout(timer);
        const timer = setTimeout(() => {
          this.userIsTyping = false;
        }, 3000);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
    animation: slide-in 0.5s;
}

.slide-leave-active {
    animation: slide-in 0.5s reverse;
}

.join {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--primary-color);
    padding: 10px 20px;
    color: white;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;

    figure {
        width: 15px;
        height: 15px;

        img {
            height: 100%;
            filter: brightness(0) invert(1);
        }
    }
}

.loading {
    &.messages {
        width: 100%;
        height: 12px;

        .loader {
            border: 2px solid #f3f3f3;
            border-top: 2px solid var(--primary-color);
            width: 12px;
            height: 12px;
        }
    }
}

.wrapper {
    position: relative;
    height: calc(100% - 4rem);
    display: flex;
    overflow: hidden;

    .video-wrapper {
        width: 0px;

        &.w-video {
            width: calc(50% - 40px);
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
        }

        .innerVideo {
            width: 100%;
            height: 100%;
        }
    }

    .whole {
        display: flex;
        width: 100%;

        &.w-video {
            width: calc(50% - 40px);
            padding: 0 0 0 20px;

            .chat-wrapper {
                .details-wrapper {
                    width: 100%;
                }
            }
        }

        .chat {
            display: flex;
            flex-direction: column;
            width: 100%;

            // padding: 20px 20px 0 20px;
            @media only screen and (max-width: 1200px) {
                width: 100%;
            }

            .chat-wrapper {
                height: 95%;
                overflow: auto;
                display: flex;
                flex-direction: column;
                gap: 20px;
                // padding: 0 20px 20px 0;
                transition: 0.2s;

                @media only screen and (max-width: 1200px) {
                    width: 100%;
                }

                .chats-tab {
                    position: sticky;
                    top: 0;
                    width: 100%;
                    background: white;
                    display: flex;
                    align-items: center;
                    gap: 2rem;
                    z-index: 4;
                    padding: 10px;
                    border-bottom: solid 1px $grey;

                    @media only screen and (max-width: 1200px) {
                        width: 100%
                    }

                    hr {
                        border: none;
                        height: 100%;
                        width: 1px;
                        background: $grey;
                    }

                    .chat-bubble {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 0.2rem;
                        user-select: none;
                        cursor: pointer;

                        &.active {

                            figure,
                            .no-img {
                                border: solid 2px $red;
                            }
                        }

                        .icon {
                            position: relative;
                        }

                        .unreads {
                            position: absolute;
                            background: $red;
                            border-radius: 50%;
                            width: 12px;
                            height: 12px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: white;
                            font-size: 8px;
                            right: 2px;
                            top: 0px;
                        }

                        figure,
                        .no-img {
                            width: 40px;
                            height: 40px;
                            background: var(--primary-color);
                            color: white;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            border: solid 2px transparent;
                            transition: 0.2s;
                            position: relative;

                            .counter {
                                position: absolute;
                                width: 15px;
                                height: 15px;
                                border-radius: 50%;
                                top: 0;
                                right: -7.5px;
                                background: $red;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 0.7rem;
                            }
                        }

                        figure {
                            overflow: hidden;
                            background: unset;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }

            .chat-bar {
                height: 5%;
            }
        }
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        width: 0px;
        overflow: hidden;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        width: 50%;
        overflow: hidden;
    }
}
</style>