<template>
  <div class="assign-container">
    <div class="search-container">
      <div class="userSearch" v-if="canEdit">
        <div class="searchPan">
          <i class="fa fa-search searchIcon" aria-hidden="true"></i>
          <input
            type="text"
            class="form-control"
            v-model="search_key"
            placeholder="Search member"
          />
        </div>
      </div>
      <div class="users" v-if="search_key.length">
        <template v-if="searchResult.length">
          <div
            v-for="user in searchResult"
            :key="user.id"
            class="user"
            @click="assignUser(user)"
            :class="{
              assigned: selectedUsers.map((el) => el.id).includes(user.user_id),
            }"
          >
            <div class="usr-info">
              <figure>
                <img
                  v-if="user.user.avatar"
                  :src="imgURL + user.user.avatar"
                  alt=""
                />
                <span v-else>{{ user.user.name.charAt(0) }}</span>
              </figure>
              <div class="name">
                {{ user.user.name }}
              </div>
            </div>
            <div class="check">
              <span
                v-if="selectedUsers.map((el) => el.id).includes(user.user_id)"
                class="assigned"
                >User already assigned</span
              >
              <span v-else class="assign">Assign User</span>
            </div>
          </div>
        </template>
        <template v-else-if="!searchResult.length && search_data_available">
          <div class="empty">
            <span>no results for your search</span>
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="selectedUsers.length && search_key.length < 1"
      class="assigned-users"
    >
      <div class="subtitle">Assigned Users</div>
      <div
        class="member-row"
        v-for="(user, user_index) in selectedUsers"
        :key="user_index"
      >
        <div class="pic">
          <img
            :src="
              user.avatar
                ? imgURL + user.avatar
                : '/assets/images/assign-member.png'
            "
            alt=""
          />
        </div>
        <div class="name">
            <span>{{ user.name }}</span>
            <span class="date">Assigned {{timeSince(user.assigned_date)}}</span>
        </div>
          
        <div
        class="form-group pointer"
        v-if="canEdit"
        @click="removeUserFromAssignedList(user.id, user_index)"
        >
            <img src="/assets/images/bin.png" alt="delete" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import { mapActions } from "vuex";
import {
  SEARCH_USERS_BY_KEY,
  UPDATE_TICKET_USERS_ASSIGNMENT_ACTION,
} from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import timeSince from '@/mixins/timeSince'
export default {
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
    canEdit: {
      required: true,
      type: Boolean,
    },
  },
  
  mixins: [timeSince],

  data() {
    return {
      timeout: null,
      title: "Remote Support History",
      ticketId: "",
      priorityStatus: "",
      search_key: "",
      searchResult: [],
      selectedUsers: this.details.assigned_users,
      search_data_available: false,
      internalInstance: getCurrentInstance(),
      imgURL: process.env.VUE_APP_DO_SPACES,
    };
  },
  watch: {
    search_key() {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.searchData();
      }, 1000);
    },
  },
  methods: {
    ...mapActions("user", {
      getUsersBySearch: SEARCH_USERS_BY_KEY,
    }),
    ...mapActions("ticket", {
      updateUserAssignment: UPDATE_TICKET_USERS_ASSIGNMENT_ACTION,
    }),
    async searchData() {
      let orgData = JSON.parse(localStorage.getItem("organization"));
      if (this.search_key.length > 2) {
        await axiosInstance
          .get(
            `get-users?org_id=${orgData.id}&searchKey=${this.search_key}&type=users&limit=100`
          )
          .then((data) => {
            this.search_data_available = true;
            if (data) {
              if (Object.keys(data.data.data.data).length !== 0) {
                this.searchResult = data.data.data.data;
              } else {
                this.searchResult = [];
              }
            } else {
              this.searchResult = [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.searchResult = [];
        this.search_data_available = false;
      }
    },

    async assignUser(user) {
      if (!this.selectedUsers.includes(user)) {
        this.selectedUsers.push({
          name: user.user.name,
          user_id: user.user.id,
          avatar: user.user.avatar,
        });
        let orgData = localStorage.getItem("organization")
          ? JSON.parse(localStorage.getItem("organization"))
          : "";
        let params = {
          ticket_id: this.ticketId,
          organization_id: orgData.id,
          users: [{ id_user: user.user.id }],
        };
        await this.updateUserAssignment(params).then((data) => {
          this.$emit("updateData");
          this.emitter.emit("alert", data);
          this.search_key = "";
        });
      } else {
        for (let i = 0; i < this.selectedUsers.length; i++) {
          const element = this.selectedUsers[i];
          if (element.id === user.id) this.selectedUsers.splice(i, 1);
        }
      }
    },
    onClickOutside() {
      this.search_key = "";
    },

    async removeUserFromAssignedList(id, index) {
      let orgData = localStorage.getItem("organization")
        ? JSON.parse(localStorage.getItem("organization"))
        : "";
      let params = {
        ticket_id: this.ticketId,
        org_id: orgData.id,
        user_id: id,
      };

      const response = await axiosInstance.post(
        "un-assign-ticket-from-user",
        params
      );
      if (response.status === 200) {
        this.emitter.emit("alert", response.data.message);
        this.selectedUsers.splice(index, 1);
      }
    },
  },
  created() {
    this.ticketId = this.$route.params.ticketid || this.details.id;
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.headerPan {
  flex-direction: column;
}

.assign-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 20px;
}

.ticket-right .assign-container {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.ticket-right .assign-container .subtitle {
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0 0 5px 0;
  width: fit-content;
  color: var(--primary-color);
}
.assign-container .headerPan .dropdown-menu {
  width: 50%;
  position: unset;
}
.userSearch .searchPan {
  border-radius: 5px;
  width: 30%;
}
.userSearch .searchPan .form-control {
  background: #f2f5f8;
  padding: 10px 30px;
  border: 1px solid transparent;
  transition: 0.2s;
  border-radius: 5px;
}
.userSearch .searchPan .form-control:focus {
  outline: none;
  border-radius: 5px;
  border: 1px solid #ced4da;
  box-shadow: unset;
}
.userSearch .searchPan .form-control::placeholder {
  color: #ced4da;
}

.search-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.assigned-users {
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow: auto;
}

.assigned-users .member-row .pic {
  width: 40px;
  height: 40px;
}
.assigned-users .member-row .pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.assigned-users .member-row {
    padding: 0 0 20px 0;
    border-bottom: solid 1px #ced4da;
}
.assigned-users .member-row .name {
    display: flex;
    flex-direction: column;
    line-height: normal;
    border: none;
    .date {
        color: grey;
        font-weight: 400;
    }
}

.users {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .user {
    display: flex;
    padding: 0.5rem 0;
    border-bottom: solid 1px #ced4da;
    .usr-info {
      width: 90%;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      figure {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          color: white;
          background: var(--primary-color);
          font-weight: 500;
        }
      }

      .name {
        font-weight: 600;
        display: flex;
        flex-direction: column;
      }
    }
    .check {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      user-select: none;
      cursor: pointer;
      transition: 0.2s;
      text-align: center;

      span {
        padding: 10px;
        border-radius: 5px;
        transition: 0.2s;
      }

      .assign {
        background: var(--primary-color);

        &:hover {
          background: #036cec;
        }
      }

      .assigned {
        background: crimson;
        &:hover {
          background: rgb(175, 11, 44);
        }
      }
    }
  }
}
</style>