<template>
    <div class="details-container">
        <div class="time">
            <div class="item" @click="showTimers = true">
                <figure>
                    <img src="/assets/images/timer.svg" alt="">
                </figure>
                <span>Check Timers</span>
            </div>
        </div>
        <main>
            <div class="title">{{ details.title }}</div>
            <div class="info">
                <div class="item">
                    <div class="subtitle">Product</div>
                    <div class="text">{{ details.product.name }}</div>
                </div>
                <div class="item">
                    <div class="subtitle">Serial</div>
                    <div class="text">{{ details.product.serial_no }}</div>
                </div>
                <div class="item">
                    <div class="subtitle">Client</div>
                    <div class="text" v-if="details.site && details.site.client">
                        <span>{{ details.site.client.client_details?.name }}</span>
                    </div>
                    <div class="text" v-else>undefined</div>
                </div>
            </div>
        </main>
        <div class="description">
            <div class="subtitle">Description</div>
            <div class="text">{{ details.description }}</div>
        </div>
        <div class="questions" v-if="details.ticket_question_data.length">
            <div class="subtitle">Ticket Questions</div>
            <div class="text">
                <template v-for="(qt, n) in details.ticket_question_data[0].ticket_questions" :key="n">
                    <div class="question">
                        <span>Q{{ n + 1 }}: {{ qt.question.question }}</span>
                        <div>
                            <span>A{{ n + 1 }}: </span>
                            <template v-for="(ans, x) in qt.answers" :key="x">
                                {{ ans.answer_details.answer }}
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <TimesPopup v-if="showTimers" :created="details.created_at" :ticket="details.id" @close="showTimers = false" />
    </div>
</template>

<script>
import TimesPopup from '@/components/Ui/Ticket/TimesPopup.vue'
export default {
  props: {
    details: {
      required: true,
      type: Object,
      default: () => {},
    },
  },

  components: {
    TimesPopup
  },

  data() {
    return {
        imgURL: process.env.VUE_APP_DO_SPACES,
        showTimers: false
    }
  },

  computed: {
    totalTime() {
        const seconds = this.details.ticket_timer.filter(el => el.user_id).reduce((a,b) => a + b.time, 0)
        const days = Math.floor(seconds / (3600*24));
        const hours = Math.floor(seconds % (3600*24) / 3600);
        const minutes = Math.floor(seconds % 3600 / 60);

        return days > 0 ? `${days} ${days < 2 ? 'day' : 'days'}` : hours > 0 ? `${hours} ${hours < 2 ? 'hour' : 'hours'}` : minutes > 0 ? `${minutes} ${minutes < 2 ? 'minute' : 'minutes'}` : `${seconds} ${seconds < 2 ? 'second' : 'seconds'}` ;
    }
  }
}
</script>

<style lang="scss" scoped>
.details-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(75vh - 74px);
    overflow: auto;
    position: relative;

    .time {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        top: 20px;
        right: 20px;

        .item {
            position: sticky;
            display: flex;
            align-items: center;
            background: rgba($grey, 0.5);
            padding: 10px;
            border-radius: 4px;
            cursor: pointer;
            user-select: none;

            figure {
                width: 16px;
                height: 16px;

                img {
                    width: 100%;
                }
            }

            span {
                margin-left: 8px;
            }
        }


    }

    main {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title {
            font-weight: bold;
            font-size: 1.3rem;
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .item {
                display: flex;
                flex-direction: column;
                gap: 2px;
            }
        }
    }

    .subtitle {
        font-weight: 500;
    }

    .text {
        font-weight: bold;
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .description,
    .timers {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    .timers {
        article {
            margin-top: 8px;
            display: flex;
            align-items: center;
            background: rgba($grey, 0.5);
            padding: 10px;
            border-radius: 4px;
            width: fit-content;

            figure {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .text {
                margin-left: 8px;
                display: flex;

                small {
                    font-size: 12px;
                    font-weight: 400;
                    font-style: italic;
                }
            }
        }
    }
}
</style>